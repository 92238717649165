<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-row>
        <v-col md="2" class="mr-2" v-if="returnAbility('student:store')">
          <v-btn
            color="green"
            class="white--text"
            @click="$router.push('/createStudent')"
          >
            <v-icon size="20">mdi-plus</v-icon>
            {{ $t("add student") }}
          </v-btn>
        </v-col>
        <v-col md="2" class="mr-2">
          <v-btn color="blue" dark block  @click="exportToExcel(dataFilter)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
        <v-col md="4">
          
        </v-col>
      
        
        <v-col md="2" class="mr-2">
        <v-switch
          @change="goToArchives"    
          v-model="is_archive"
          color="green"
          :label="$t('Archives')"
        ></v-switch>
      </v-col>
        <v-col md="12" class="mr-2">
          <students-filter
            @setDataFilter="setDataFilter($event)"
          ></students-filter>
        </v-col>
      </v-row>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        :serverRoute="'/student'"
        :enableDelete="displayDeleteIcon"
        :flaggedItems="flaggedItemsData"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon
            color="red"
            @click="handleDelete(row.id)"
            v-if="returnAbility('student:update')"
            >mdi-delete</v-icon
          >
          <v-icon
            color="info"
            @click="$router.push(`/editStudent/${row.id}`)"
            v-if="returnAbility('student:update') & (!is_archive)"
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="returnAbility('student:show')"
            color="green"
            @click="
              $router.push(
                `registrationRecord/${row.id}/statement/${row.account_id}`
              )
            "
            >mdi-eye</v-icon
          >
          <v-icon
            v-if="returnAbility('student:show') & is_archive"
            color="red"
            @click="restoreStudent(row.id)
            "
            >mdi-floppy</v-icon
          >
        </template>
       
      </app-base-table>
      <v-row>
        <v-col md="8"></v-col>
        <v-col md="2" class="mr-2">
          <v-switch
          @change="goToAll"    
          v-model="is_all"
          color="green"
          :label="$t('Show All')"
        ></v-switch>
        </v-col>
      <v-col md="1" class="mr-2">
          <v-text-field
            v-model="page"
            dense
            :label="$t('Page Number')"
            @change="fetchData"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import studentsFilter from "./studentsFilter.vue";
import axios from "axios";
import { saveAs } from 'file-saver';
export default {
  components: {
    studentsFilter,
  },
  data() {
    return {
      is_archive: false,
      is_all: false,
      archive: 2,
      paginated: true,
      delete_dialog: false,
      page: 1,
      deleteItemId: null,
      confirm_delete: false,
      dataFilter: {
        grade: null,
        id: null,
        edu_class: null,
        name: null,
        type: null,
        gender: null,
        last_name: null,
        father_name: null,
        mother_name: null,
        public_record_number: null,
        phone_number: null,
      },
      flaggedItemsData: ["number_transfer_document", "account_id", "id"],
    };
  },
  watch:{
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/student/${this.deleteItemId}`).then((res) => {
          
          if (res.status == 200) {
            this.$Notifications(
              "تم الحذف  بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
           
          }
          
        });
        this.fetchData(this.page);
        this.delete_dialog = false;
        this.confirm_delete = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      tableOptions: "student/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchStudents: "student/fetchStudents",
    }),
    goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.setDataFilter(this.dataFilter);
    },
    goToArchives(){
      if(this.is_archive){
        this.archive = 1;
      } else { this.archive = 2; }
      this.setDataFilter(this.dataFilter);
    },
    async restoreStudent(id){
      await axios.put(`student/restore/${id}`);
      this.fetchData(this.page);
    },
    fetchData(paginationValue) {
      this.page = paginationValue;
      localStorage.setItem("page",this.page);
      this.fetchStudents({
        paginationValue,
        paginated: this.paginated,
        id: this.dataFilter.id,
        archive: this.archive,
        grade: this.dataFilter.grade,
        edu_class: this.dataFilter.edu_class,
        name: this.dataFilter.name,
        type: this.dataFilter.type,
        gender: this.dataFilter.gender,
        last_name: this.dataFilter.last_name,
        father_name: this.dataFilter.father_name,
        mother_name: this.dataFilter.mother_name,
        public_record_number: this.dataFilter.public_record_number,
        phone_number: this.dataFilter.phone_number,
      });
    },
    setDataFilter(data) {
      this.dataFilter = data;
      let paginationValue = 1;
      console.log("data",data);
      this.fetchStudents({
        paginationValue,
        archive: this.archive,
        paginated: this.paginated,
        grade: data.grade,
        id: data.id,
        edu_class: data.edu_class,
        name: data.name,
        type: data.type,
        gender: data.gender,
        last_name: data.last_name,
        father_name: data.father_name,
        mother_name: data.mother_name,
        public_record_number: data.public_record_number,
        phone_number: data.phone_number,
      });
    },
    async handleDelete(item) {
      this.deleteItemId = item;
      this.delete_dialog = true;
    },
    async exportToExcel(queryParams){
         
         try {
    
     const excel = await axios.get(`/student/export`, {
       params: {
        archive: this.archive,
         ...queryParams,
         
       },
       responseType: 'blob'
     });
     saveAs(excel.data)
     

   } catch (err) {
     console.log('err', err);
   } finally {
     
   }
     
     },
  },
  created() {
    if(localStorage.getItem("page")){
      this.page = localStorage.getItem("page");
    }
    if (this.returnAbility("student:destroy")) {
      this.displayDeleteIcon = false;
    }
    this.fetchData(this.page);
  },
};
</script>

<style></style>
